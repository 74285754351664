








































































































































































.userLogin {
	.newTitleTwo {
		background: #fff;
	}

	/* @import "../../assets/css/puliceStyle.css"; */

	input:-webkit-autofill {
		box-shadow: 0 0 0px 1000px white inset !important;
	}

	.pageTitle {
		font-size: 0.48rem;
		font-weight: Bold;
		line-height: 0.78rem;
		margin-bottom: 1.2rem;
	}

	.isShowPwd {
		display: inline-block;
		float: right;
		margin-top: 0.2rem;
		width: 0.34rem;
		height: 0.28rem;
	}

	.isShowPwd img {
		width: 100%;
		height: 100%;
	}

	.linkLIne {
		font-size: 0.26rem;
		color: #333;
		margin-top: 0.64rem;
	}

	.body1StyleInputStyle {
		border: 0;
		font-size: 0.28rem;
		width: 100%;
		line-height: 0.8rem;
		display: block;
	}

	.bodyStyleDivStyleP {
		// margin: 0.56rem 0 0.2rem 0;
	}

	.bodyStyleDivStyle {
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
		padding-bottom: 0.1rem;
		// margin-bottom: 1rem;
	}

	.body1Style {
		padding: 0.3rem;
	}

	.bottom1 {
		position: fixed;
		width: 100%;
		bottom: 0.2rem;
	}

	.bottom1 p {
		margin-left: 0.3rem;
		font-size: 0.26rem;
		color: #333;
	}

	.bottom1 .agree {
		color: #a2a2a2;
		font-size: 0.26rem;
		margin-bottom: 0.4rem;
	}

	.bottom1 .agree a {
		color: #000;
	}
	.zh-btn-type {
		text-align: right;
		padding-top: 10px;
		font-size: 0.28rem;
		color: #ff4b04;
	}
}

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;